import React, { Fragment } from 'react'
import Link from 'gatsby-link'
import Showdown from 'showdown'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import CardRelated from '../components/cardRelated'
import Cta from '../components/cta'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

const StategyTemplate = ({ data }) => {
  const post = data.markdownRemark
  const converter = new Showdown.Converter()  

  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}            
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg={post.frontmatter.headline && post.frontmatter.headline.length >= 1 ? '8' : '12'}>
                <div
                className={`font-w-400 text-xs-extra-large divlink ${post.html ? 'text-opening-para' : 'text-opening-para-mb0'}`}
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.openingpara), }}
              />
              {post.html && (
                <div
                  className="richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              )}
              </MDBCol>

              {post.frontmatter.headline && post.frontmatter.headline.length >= 1 && (
                <MDBCol lg="4" md="12" className="pl-lg-5">
                  {post.frontmatter.headline.map((headlines, index) => (
                    <div
                      className="headline-tile mt-lg-3 item-headline"
                      key={index}
                    >
                      <div className="headline-icon">
                        <FontAwesomeIcon
                          icon={['fat', headlines.icon]}
                          size="2x"
                          className="fa-fw"
                        />
                      </div>
                      <div className="headline-content">
                        <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-medium text-medium">
                          {headlines.title}
                        </h3>
                        <div className="headline-action font-alt letter-spacing-1 text-xs-medium text-small">
                          <Link to={headlines.link} className="effect-noline">
                            {headlines.linktext}
                            <FontAwesomeIcon
                              icon={faChevronRight}
                              className="ml-2"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </MDBCol>
              )}
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.strapline && (
          <section className="blockback">
            <MDBContainer>
              <div className="blockquote-wrapper">
                <div className={`${post.frontmatter.author.toLowerCase().indexOf("gartner") !== -1 ? 'gartner blockquote': 'blockquote'}`}>
                  <div className="strapline">{post.frontmatter.strapline}</div>
                  <div className="author">{post.frontmatter.author}</div>
                </div>
              </div>
            </MDBContainer>
          </section>
        )}

        {(post.frontmatter.related && post.frontmatter.related.length > 0) &&
        <section className="bg-light-blue">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12" className="mb-5">
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                Related products, solutions and news
                </h2>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              {post.frontmatter.related.map((relate, index) => (
                <Fragment key={index}>
                  {(relate.document || relate.link) && (
                    <CardRelated
                      key={index}
                      collg={((post.frontmatter.related.length % 4 === 0) && (!post.frontmatter.related.length % 3 === 0)) ? "6" : "4"}
                      colmd="6"
                      height="8.5rem"
                      type={relate.type}
                      title={relate.title}
                      description={relate.description}
                      linktext={relate.linktext}
                      link={relate.link}
                    />
                  )}
                </Fragment>
              ))}
            </MDBRow>
          </MDBContainer>
        </section>
      }

      {post.frontmatter.cta && 
        <Cta
          ctatitle={post.frontmatter.cta.title}
          ctadescription={post.frontmatter.cta.description}
          ctalink={post.frontmatter.cta.link}
          ctalinktext={post.frontmatter.cta.linktext}
        />
      }
      </Layout>
    </div>
  )
}
export default StategyTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {   
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "strategy" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description 
        }         
        template
        title
        subtitle
        description
        openingpara
        strapline
        author
        ctatext
        ctaslug        
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        related {
          type
          title
          description
          linktext
          link
        }
        cta {
          title
          description
          linktext
          link
        }        
      }
    }
  }
`
